import React from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import './NavMenu.css';


export default class NavMenu extends React.Component<{}, { isOpen: boolean }> {
    constructor(props: {}) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div>
                <Navbar color="light" light expand="md">
                    <NavbarBrand className="mr-auto">La Lista Negra del SAT</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/">Inicio</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/invoiceValidation">Valida tu Factura</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/archives">Histórico de Archivos</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/faq">Preguntas Frecuentes</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/forDevs">Desarrolladores</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/contact">Contacto</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={this.toggle} tag={Link} className="text-dark" to="/noticyOfPrivacy">Aviso de Privacidad</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}